<template>
  <Page v-bind="pageData">
    <div class="u-display--flex u-justify-content--center u-padding-top--1">
      <Spinner aria-label="Loading" :show="true" />
      Loading...
    </div>
  </Page>
</template>

<script lang="ts">
import Vue from "vue";
import Spinner from "atlas/src/components/Spinner/Spinner.vue";
import { InitPageParams } from "lib/utils/initPage";
import Page from "~/components/Page/Page.vue";
import { initPage } from "~/lib/utils/initPage";
import { AccessData } from "~/lib/types/Auth0/AccessData";
import { getJsonLd } from "~/lib/mappers/JsonLdMapper";
import { fetchParser } from "~~/lib/utils/datetime/dateTimeReviver";
import {
  setItemtoLocalStorage,
  getItemFromLocalStorage,
} from "~/lib/utils/manageLocalStorageItems";
import { utag } from "~/components/ModularPage/ContentstackModularPageContentDecorator";
import { WishlistItem, Wishlist } from "~/lib/types/Salesforce/Wishlist";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Page],
});

type AuthResult = {
  accessToken: string;
  idToken: string;
  expiresIn: number;
  sub?: string;
};

type CompositeResponseBody = {
  errorCode?: string;
  errors: Array<string>;
  id: string;
  success: boolean;
};

type CompositeResponse = {
  body: CompositeResponseBody | CompositeResponseBody[];
  httpStatusCode: number;
};

type Response = {
  compositeResponse: CompositeResponse[];
};

type Contact = {
  contactID: string;
  Name: string;
};

const BATCH_SIZE = 25;

export default Vue.extend({
  name: "Loggedin",
  components: {
    Spinner,
    Page,
  },
  async asyncData({
    route,
    $auth,
    store,
    i18n,
    params,
    req,
    $config,
  }): Promise<AccessData | undefined> {
    try {
      const initPageParams: InitPageParams = {
        locale_iso: i18n.localeProperties.iso,
        locale_code: i18n.locale,
        locale_domain: $config.public.baseUrl,
        path: route.path,
        slug: `/${params.pathMatch}`,
        currency_code:
          params.currencyCode || i18n.localeProperties.currencyCode,
      };
      await Promise.all(initPage(store, initPageParams));

      const code = route.query.code;
      const accessData: AccessData = {
        accessToken: "",
        idToken: "",
        expiresIn: 0,
      };

      const host: string = (req.headers["x-forwarded-host"] ||
        req.headers.host!) as string;

      if (code) {
        const data: AccessData = await $fetch("/api/nuxt/auth0/access-token", {
          params: { code, host },
        });

        if (data) {
          await $auth.setUserToken(data.accessToken);
          return data;
        }
      }
      return accessData;
    } catch (error) {
      logger.error("auth0 loggedin failed", error);
    }
  },
  data(): AccessData {
    return {
      accessToken: "",
      idToken: "",
      expiresIn: 0,
    };
  },
  computed: {
    pageData() {
      const metaData = [
        {
          property: "og:url",
          content: `${this.$config.public.baseUrl}${this.$route.fullPath}`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: this.$config.public.defaultMetaImage,
        },
      ];

      const jsonldData = getJsonLd({
        ...this.$config.public,
      })({
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: this.$config.public.baseUrl,
        name: "Intrepid Travel",
      });

      return {
        jsonld: jsonldData,
        utag: utag(
          this.$i18n.localeProperties,
          this.$route.fullPath,
          "wishlist-loggedin-page"
        ),
        metadata: metaData,
        title: "My wishlist | Intrepid Travel",
        banner: undefined,
        breadcrumb: undefined,
      };
    },
  },
  async mounted() {
    const authResult: AuthResult = {
      ...this.$auth.user,
      ...{
        accessToken: this.accessToken,
        idToken: this.idToken,
        expiresIn: this.expiresIn,
      },
    };
    await this.setLocalStorage(authResult);
    await this.handleMergeWishlist();
    await this.$router.push({ path: "/" + this.$i18n.locale + "/wishlist" });
  },
  methods: {
    async setLocalStorage(authResult: AuthResult) {
      const expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      await setItemtoLocalStorage("access_token", authResult.accessToken);
      await setItemtoLocalStorage("id_token", authResult.idToken);
      await setItemtoLocalStorage("expires_at", expiresAt);
      if (authResult.sub) {
        await setItemtoLocalStorage(
          "auth0_id",
          authResult.sub.replace("auth0|", "")
        );
      }

      for (const [key, value] of Object.entries(authResult)) {
        if (key.endsWith("first_name")) {
          await setItemtoLocalStorage("auth_firstname", value.toString());
          await this.$store.dispatch("user/setFirstName", value);
        }
        if (key.endsWith("last_name")) {
          await setItemtoLocalStorage("auth_lastname", value.toString());
        }
        if (key.endsWith("email")) {
          await setItemtoLocalStorage("auth_email", value.toString());
        }
      }
    },

    async createSalesforceAccount() {
      try {
        const response: Response = await $fetch(
          "/api/nuxt/salesforce/create-contact",
          {
            method: "POST",
            body: {
              correlationID: getItemFromLocalStorage("auth0_id"),
              firstName: getItemFromLocalStorage("auth_firstname"),
              lastName: getItemFromLocalStorage("auth_lastname"),
              email: getItemFromLocalStorage("auth_email"),
            },
            parseResponse: fetchParser,
          }
        );

        const contact = response?.compositeResponse[1]
          ?.body as CompositeResponseBody;
        if (contact?.success) {
          return contact.id;
        }
      } catch (error) {
        logger.error("createSalesforceAccount failed", error);
      }
    },

    async updateSalesforceAccount(contactID: string) {
      try {
        const response: Response = await $fetch(
          "/api/nuxt/salesforce/update-contact",
          {
            method: "PUT",
            body: {
              contactID,
              correlationID: getItemFromLocalStorage("auth0_id"),
            },
            parseResponse: fetchParser,
          }
        );
        if (response?.compositeResponse[0].httpStatusCode === 204) {
          return contactID;
        }
      } catch (error) {
        logger.error("update salseforce accout failed", error);
      }
    },

    async getSalesforceContact() {
      try {
        const contact: Contact = await $fetch("/api/nuxt/salesforce/contact", {
          params: {
            correlationID: getItemFromLocalStorage("auth0_id"),
          },
          parseResponse: fetchParser,
        });

        if (contact && contact.contactID) {
          return contact.contactID;
        } else {
          const existingContact =
            (await this.getSalesforceContactBySignupEntry()) as Contact;
          if (existingContact && existingContact.contactID) {
            return await this.updateSalesforceAccount(
              existingContact.contactID
            );
          }
          return await this.createSalesforceAccount();
        }
      } catch (error) {
        logger.error("getSalesforceContact failed", error);
      }
    },

    async getSalesforceContactBySignupEntry() {
      try {
        return await $fetch("/api/nuxt/salesforce/existing-contact", {
          params: {
            firstName: getItemFromLocalStorage("auth_firstname"),
            lastName: getItemFromLocalStorage("auth_lastname"),
            email: getItemFromLocalStorage("auth_email"),
          },
          parseResponse: fetchParser,
        });
      } catch (error) {
        logger.error("getSalesforceContact failed", error);
      }
    },

    async handleMergeWishlist() {
      const wishlist = getItemFromLocalStorage("wishlist");
      const wishlistItems: Wishlist = wishlist ? JSON.parse(wishlist) : null;

      const contactID = await this.getSalesforceContact();
      const products: WishlistItem[] = wishlistItems
        ? Object.entries(wishlistItems.items).map((item) => item[1])
        : [];

      if (products.length > 0 && contactID) {
        let batchIndex = 0;
        while (batchIndex < products.length) {
          const batch: WishlistItem[] = products.slice(
            batchIndex,
            batchIndex + BATCH_SIZE
          );
          await this.mergeWishlist(batch, contactID);
          batchIndex += BATCH_SIZE;
        }
      }
    },

    async mergeWishlist(products: WishlistItem[], contactID: string) {
      try {
        const result: Response = await $fetch("/api/nuxt/wishlist/merge", {
          method: "POST",
          body: {
            products,
            contactID,
          },
        });
        if (result) {
          const haltedProducts: WishlistItem[] = [];
          result.compositeResponse.forEach(
            (response: CompositeResponse, index: number) => {
              if (
                response.httpStatusCode === 400 &&
                (response.body as CompositeResponseBody[])[0]?.errorCode ===
                  "PROCESSING_HALTED"
              ) {
                haltedProducts.push(products[index]);
              }
            }
          );

          if (haltedProducts.length > 0) {
            await this.mergeWishlist(haltedProducts, contactID);
          }
        }
        return result;
      } catch (error) {
        logger.error("mergeWishlist failed", error);
      }
    },
  },
});
</script>
